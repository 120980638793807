<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">
        <el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增</el-button>
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>基础设置</el-breadcrumb-item>
          <el-breadcrumb-item>学期管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div style="height: 85%">
      <el-table height="100%" :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border stripe>
        <el-table-column type="index" label="序号" width="60px"></el-table-column>
        <el-table-column label="学期名称" prop="title"></el-table-column>
        <el-table-column label="学期年份" prop="term_year"></el-table-column>
        <el-table-column label="学期开始时间" prop="term_start"></el-table-column>
        <el-table-column label="学期结束时间" prop="term_end"></el-table-column>

        <!-- <el-table-column prop="sort" width="80px" label="排序"></el-table-column> -->

        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red"
              @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
              <a slot="reference" size="small" style="
                  color: red;
                  font-size: 12px;
                  margin-right: 10px;
                  cursor: pointer;
                ">删除</a>
            </el-popconfirm>
            <el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog ref="ruleForm" :title="modalTitle" :visible.sync="dialogForm" :rules="rules">
      <el-form :model="formData" label-width="120px">
        <el-form-item label="学期名称" prop="title">
          <el-input v-model.trim="formData.title" style="width: 50%"></el-input>
        </el-form-item>

        <el-form-item label="学期年份" prop="title">
          <el-date-picker v-model="formData.term_year" type="year" placeholder="选择年">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="学期开始时间" prop="title">
          <el-date-picker v-model="formData.term_start" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="学期结束时间" prop="title">
          <el-date-picker v-model="formData.term_end" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="false" label="校历图片" prop="title">
          <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss"
            :on-success="uploadFiles1" :show-file-list="false" name="xiaoli_img">
            <img :src="formData.xiaoli_img?formData.xiaoli_img: './img/temp.png'" style="width:100%" />
          </el-upload>

          <span v-if="!formData.xiaoli_img" @click="setUpTerm"
            style="font-size:12px;color:#666;cursor:pointer">复用上学期</span>
        </el-form-item>
        <el-form-item v-if="false" label="作息安排图片" prop="title">
          <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss"
            :on-success="uploadFiles2" :show-file-list="false" name="zuoxi_img">
            <img :src="formData.zuoxi_img || './img/temp.png'" style="width:100%" />
          </el-upload>
        </el-form-item>
        <el-form-item v-if="false" label="教室安排图片" prop="title">
          <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss"
            :on-success="uploadFiles3" :show-file-list="false" name="jiaoshi_img">
            <img :src="formData.jiaoshi_img || './img/temp.png'" style="width:100%" />
          </el-upload>
        </el-form-item>

        <!-- <el-form-item label="排序号" prop="sort">
					<el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
						controls-position="right" />
				</el-form-item> -->
      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right">
          <el-button size="small" type="default" @click="dialogForm = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <div style="height: 20px"></div>
    <div style="
        position: absolute;
        bottom: 70px;
        right: 8px;
        height: 30px;
        width: 90%;
        text-align: right;
        background-color: #fff;
        z-index: 20;
      ">
      <el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
        :page-size="page.per_page" :total="page.count" layout="total, prev, pager, next">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogForm: false,
      modalTitle: "添加",
      formData: {},
      page: {
        count: 0,
        current_page: 1,
        per_page: 11,
        total_page: 0,
      },
      overlap: null,
      rules: {
        title: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    uploadFiles1(e) {
     
      this.formData.xiaoli_img = e.src
      this.$forceUpdate()
     
    },
    uploadFiles2(e) {
      this.formData.zuoxi_img = e.src
      this.$forceUpdate()
    },
    uploadFiles3(e) {
      this.formData.jiaoshi_img = e.src
      this.$forceUpdate()
    },
    setUpTerm(){
      let next=0;
      for(let i=0;i<this.tableData.length;i++){
        if(this.tableData[i].id==this.formData.id){
          next=i+1;
          break
        }
      }
      if(this.tableData.length<=next){
        this.$message.error("没有上学期")
        return
      }else  if(!this.tableData[next].xiaoli_img){
        this.$message.error("上学期未设置校历，作息图片")
        return
      }
      this.formData.xiaoli_img = this.tableData[next].xiaoli_img;
      this.formData.zuoxi_img = this.tableData[next].zuoxi_img;
      this.formData.jiaoshi_img = this.tableData[next].jiaoshi_img;
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    formAdd() {
      this.dialogForm = true;
      this.modalTitle = "添加";
      this.formData = {
        xiaoli_img: "",
        zuoxi_img: "",
        jiaoshi_img: ""
      };
    },
    getList() {
      this.$http.post("/api/t_sch_terms_list").then((res) => {
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    handleDel(row) {
      this.$http
        .post("/api/t_sch_terms_delete", {
          id: row.id,
        })
        .then((res) => {
          this.$message.success("删除成功");
          this.getList();
        });
    },
    handleModify(row) {
      row.term_year += "";
      this.dialogForm = true;
      this.modalTitle = "修改";
      this.formData = row;
      row.aa = 1
    },
    submitForm(formName) {
      if (
        this.formData.term_year &&
        this.formData.title &&
        this.formData.term_start &&
        this.formData.term_end
      ) {
        let term_start = new Date(this.formData.term_start).getTime();
        let term_end = new Date(this.formData.term_end).getTime();
        if (term_start < term_end) {
          let d = new Date(this.formData.term_year);
          this.formData.term_year = String(d.getFullYear());

          const isOverlap = (StartA, EndA, StartB, EndB) => {

            const getT = (t) => new Date(t);
            return getT(EndA) < getT(StartB) || getT(StartA) > getT(EndB);
          };
          this.overlap = true;
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].aa) {
              this.overlap = true;
            } else {
              let bol = null;
              bol = isOverlap(
                this.tableData[i].term_start,
                this.tableData[i].term_end,
                this.formData.term_start,
                this.formData.term_end
              );
              if (bol) {
                bol = true;
              } else {
                bol = false;
              }
              this.overlap = bol;
            }
          }
          console.log(this.overlap)
          if (this.overlap) {
            this.$http
              .post("/api/t_sch_terms_edit", this.formData)
              .then((res) => {
                this.$message.success("保存成功");
                this.dialogForm = false;
                this.getList();
              });
          } else {
            this.$message({
              message: "选中日期不能跟之前重合",
              type: "error",
            });
          }
        } else {
          this.$message({
            message: "结束时间必须大于开始时间",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "所填内容不能为空",
          type: "warning",
        });
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>

</style>